import React from "react";
import classnames from "classnames";
import { Modal, Toast } from "antd-mobile";
import { BaseLayout, Footer } from "components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { isIOS } from "utils";
import { linkConfig } from "config";
import styles from "./styles.module.scss";
import queryString from "query-string";
const Alert = Modal.alert;
class Download extends React.PureComponent {
  state = {
    androidUrl: linkConfig.download.androidUrl["eetrade"],
    loading: false,
    title: "立即下载",
    slogan: "EE TRADE",
    platform: "eetrade",
    copyrightTitle: "EETRADE",
    detail: "https://www.woyaotf.cn/app_merge/BTHQ",
    copy: false,
    step: false,
  };
  constructor(props) {
    super(props);
    this.$download = React.createRef();
    this.$copy = React.createRef();
  }

  componentDidMount() {
    if (window.location.search) {
      const params = queryString.parse(window.location.search);
      if (
        params.platform &&
        params.title &&
        params.url &&
        Object.keys(linkConfig.download.ios).includes(params.platform)
      ) {
        this.setState({
          androidUrl: params.url,
          slogan: params.title,
          platform: params.platform,
          detail: `${this.state.detail}${window.location.search}`,
        });
      }
    }
  }
  startDownload = () => {
    this.setState({
      loading: true,
      title: "获取中...",
      step: isIOS() ? true : false,
    });
    const ua = window.navigator.userAgent.toLowerCase();
    const isWeixinBrowser = !!ua.match(/MicroMessenger/i);
    const isWhiteBrowser = !!ua.match(/MQQBrowser|Safari|UCBrowser/i);
    this.$download.current && this.$download.current.click();
    if (!isWhiteBrowser && isIOS() && !isWeixinBrowser) {
      this.setState({
        copy: true,
      });
      Alert("请在Safair浏览器中打开此页面并再次点击“立即下载”按钮", "", [
        { text: "取消" },
        {
          text: "复制链接",
          onPress: this.copy,
        },
      ]);
      return;
    }
    this.$timer = setTimeout(() => {
      this.setState({
        loading: false,
        title: "立即下载",
      });
      clearTimeout(this.$timer);
    }, 3000);
  };
  copy = () => {
    this.$copy.current && this.$copy.current.click();
    Toast.success("复制成功！", 1);
  };
  hideDetail = () => {
    this.setState({
      step: false,
    });
  };
  componentWillUnmount() {
    clearTimeout(this.$timer);
  }
  render() {
    const ua = window.navigator.userAgent.toLowerCase();
    const isWeixinBrowser = !!ua.match(/MicroMessenger/i);
    const url = isIOS() ? this.state.detail : this.state.androidUrl;
    console.log(this.state.androidUrl);
    const AppStoreUrl =
      "https://apps.apple.com/cn/app/%E5%8D%97%E5%8D%8E%E9%87%91%E4%B8%9A/id6477434010";
    return (
      <BaseLayout>
        <div
          className={classnames("full-screen", styles.download, {
            [styles.fixed_scroll]: this.state.step || this.state.detail,
          })}
        >
          {isWeixinBrowser ? (
            <div
              className={classnames(styles.shadow, {
                [styles.android]: !isIOS(),
              })}
            />
          ) : null}
          <h2>合约交易 触手可及</h2>
          <p className={styles.summary}>全球著名的差价合约交易平台</p>

          <div
            className={styles.animate}
            style={{
              backgroundImage: `url("/download/logo-circle.png")`,
            }}
          />
          <h3>{this.state.slogan} App下载 </h3>
          <p className={styles.strength}>
            <span>安全</span>
            <span>稳定</span>
            <span>智能</span>
            <span>服务</span>
          </p>
          <div className={styles.download_wrapper}>
            <div className="flex-container flex-space-between layout-vertical">
              {isIOS() ? (
                <React.Fragment>
                  <div className={styles.box}>
                    <a
                      href={AppStoreUrl}
                      className={classnames(
                        styles.download_btn_ios,
                        styles.download_btn_appStore,
                        {
                          [styles.loading]: this.state.loading,
                        }
                      )}
                    >
                      <span className={styles.ios_icon} />
                      App Store
                    </a>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <a
                    ref={this.$download}
                    download
                    href={url}
                    rel="noopener noreferrer"
                    className={classnames(
                      "flex-block",
                      styles.download_btn_and,
                      {
                        [styles.loading]: this.state.loading,
                      }
                    )}
                  >
                    立即下载
                    <span className="label" />
                  </a>
                </React.Fragment>
              )}
            </div>
          </div>
          {this.state.copy ? (
            <CopyToClipboard
              className="visible-hide"
              text={`${window.location.origin}/download${window.location.search}`}
            >
              <i ref={this.$copy} />
            </CopyToClipboard>
          ) : null}
          <Footer title={this.state.copyrightTitle} />
        </div>
      </BaseLayout>
    );
  }
}

export default Download;
